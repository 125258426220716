<template>
 <div>
     <div v-if="!show">
        <div style="padding-top: 10%">
        <v-col xs12>
            <v-row align-center justify-center row fill-height>
                <div>
                    <div class="text-xs-center">
                        <v-card flat>
                            <v-progress-circular  :size="100" :width="7" color="primary"  indeterminate></v-progress-circular>
                        </v-card>                   
                    </div> 
                </div>
            </v-row>
        </v-col>
    </div>
    </div>
    <div v-if="show">{{message}}</div>
 </div>
</template>
<script>
import api from '@/api/autenticacion'
export default {
  data: () => {
    return {
      show: false,
      message: ''
    }
  },
  mounted () {
    let token = window.location.href.split('?')[1]
    var data = new FormData()
    data.append('token', token)
    api.validarCorreoDesbloqueo(data)
    .then(response => {
      this.message = response.data
      this.show = true
    })
    .catch(error => {
      alert(error.response.data.Message)
    })
  }
}
</script>

